<template>
  <div v-if="lodd" class="offline">
    <Loading />
  </div>
  <div v-else class="container">
    <div class="w-100">
      <p class="top">Kindly fill this form to proceed with your transaction.</p>
      <form @keyup="checkForm">
        <div class="row">
          <div class="col-md-6">
            <input
              type="text"
              readonly
              name=""
              id=""
              :value="udet.first_name + ' ' + udet.last_name"
              placeholder="Username"
              class="form-control"
            />
          </div>
          <div class="col-md-6">
            <input
              type="text"
              readonly
              name=""
              id=""
              :value="udet.email"
              class="form-control"
              placeholder="Email Address"
            />
          </div>
          <div class="col-md-6">
            <label for="">Phone Number</label>
            <input
              type="text"
              name=""
              id=""
              placeholder="Phone Number"
              :value="udet.phone_number"
              readonly
              class="form-control"
            />
          </div>
          <div class="col-md-6">
            <label for="">Delivery Address <span class="imp">*</span> </label>
            <input
              type="text"
              name=""
              id=""
              placeholder="Address"
              class="form-control"
              v-model="address"
              required
            />
          </div>
          <div class="col-md-6">
            <label for=""
              >Sterling Account Number <span class="imp">*</span>
            </label>
            <input
              type="number"
              name=""
              id=""
              placeholder="Sterling Account Number "
              class="form-control"
              v-model="account_number"
              required
            />
          </div>
          <div class="col-md-6">
            <label for="">Sterling Branch <span class="imp">*</span></label>
            <input
              type="text"
              name=""
              id=""
              v-model="branch"
              placeholder="Sterling Branch"
              class="form-control"
              required
            />
          </div>
          <div class="col-md-6">
            <label>Cost of Product(s)</label>
            <input
              type="text"
              readonly
              name=""
              id=""
              :value="'₦ ' + numberFormat(subtotal)"
              placeholder="Cost of Products"
              class="form-control"
            />
          </div>
          <div class="col-md-6">
            <label>Total Cost </label>
            <input
              type="text"
              readonly
              name=""
              id=""
              :value="'₦ ' + numberFormat(total)"
              placeholder="Cost of Products"
              class="form-control"
            />
          </div>
          <div class="col-md-6">
            <label for="">Equity Amount</label>
            <input
              type="number"
              name=""
              id=""
              v-model="equity"
              placeholder="Equity Amount"
              class="form-control"
            />
          </div>
          <div class="col-md-6">
            <label>Loan Amount</label>
            <input
              type="text"
              readonly
              name=""
              id=""
              :value="'₦ ' + numberFormat(loan)"
              placeholder="Loan Amount"
              class="form-control"
            />
          </div>
          <div class="col-12">
            <p>Please confirm these are the items in your cart:</p>
            <div class="row">
              <div class="col-md-6" v-for="(item, index) in cart" :key="index">
                <div class="citm">
                  <div class="nme">
                    <h5>{{ item.product.name }}</h5>
                  </div>
                  <div class="prc">
                    <h6>
                      ₦ {{ numberFormat(item.product.price) }} x
                      {{ item.quantity }}
                    </h6>
                  </div>
                </div>
              </div>
              <!-- <div class="col-12 mt-60">
                <div class="citm">
                  <div class="nme">
                    <h4>Delivery Fee</h4>
                  </div>
                  <div class="prc">
                    <h6>₦ {{ numberFormat(deliveryFee) }}</h6>
                  </div>
                </div>
              </div> -->
            </div>
          </div>
          <div class="col-12 concent">
            <input type="checkbox" v-model="aggreed" id="chh" />
            <label for="chh"
              >I agree that all item(s) listed here are the same items I added
              to my cart.</label
            >
          </div>
          <div class="col-12 forc">
            <button
              @click.prevent="Submit"
              :disabled="!aggreed || !completed"
              class="sbtn"
            >
              Confirm Transaction
            </button>
          </div>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import Loading from "@/components/Loading.vue";
export default {
  components: { Loading },
  data() {
    return {
      // usrname: "User Name",
      // usrmail: "User@mail.com",
      // phone_number: "",
      lodd: false,
      udet: {
        first_name: "",
        last_name: "",
        email: "",
        phone_number: "",
      },
      subtotal: 0,
      deliveryFee: 0,
      cart: [],
      total: 0,
      aggreed: false,
      completed: false,

      address: "",
      branch: "",
      account_number: "",
      equity: "",

      // loan: 0 ,
    };
  },
  computed: {
    loan() {
      if (this.equity > this.total) {
        this.equity = "";
        return this.total;
      } else {
        return this.total - this.equity;
      }
    },
  },
  mounted() {
    var exist = localStorage.getItem("identifier");
    var storedcart = this.$store.getters.cartProducts;

    // console.log(storedcart);
    if (exist) {
      let load = {
        path: `/offline/${exist}`,
        // data,
      };

      this.$store
        .dispatch("getRequest", load)
        .then((resp) => {
          // console.log(resp);

          if (resp.status === 200 && resp.data.status === "success") {
            // localStorage.setItem("identifier", this.$route.query.inviteToken);
            // console.log("E correct");
            // console.log(resp.data.data);
            this.udet = resp.data.data;
            // this.$router.push("/categories");

            if (resp.data.data.loan_amount) {
              localStorage.removeItem("identifier");
              this.$toast.info(
                "Offline Transaction",
                "Link has already been used",
                this.$toastPosition
              );
              setTimeout(() => {
                this.$router.push("/");
              }, 1000);
            }
            // this.$toast.success(
            //   "Offline Transaction",
            //   "True",
            //   this.$toastPosition
            // );
          } else {
            localStorage.removeItem("identifier");
            this.$router.push("/");
          }
        })
        .catch((err) => {
          // console.log(err);
          localStorage.removeItem("identifier");
          this.$router.push("/");

          // this.$router.push("/");
        });
    }

    // console.log(storedcart);
    // console.log(storedcart.length);
    if (!exist) {
      this.$router.push("/categories");
    }
    if (storedcart.length < 1) {
      this.$router.push("/categories");
      this.$toast.info(
        "Offline Transaction",
        "Please add item(s) to cart",
        this.$toastPosition
      );
    } else {
      this.cart = this.$store.getters.cartProducts;
      var amount = 0;
      var delivery = 0;
      this.cart.forEach((item) => {
        var unitprice = 0;
        unitprice = item.product.price * parseInt(item.quantity);
        amount += unitprice;
        delivery += item.product.delivery_cost;
        // console.log(item.product.price);
        // console.log(amount);
        this.subtotal = amount;
        this.deliveryFee = delivery;

        this.total = this.subtotal;
      });
    }

    // this.loan = this.total - this.equity
    // console.log(this.equity);
    // console.log(this.loan);
  },
  methods: {
    numberFormat(x) {
      return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    },
    checkForm() {
      // console.log("checking");
      this.completed = false;
      // console.log(this.address);

      if (this.address.length > 7) {
        var addr = true;
        // console.log("Address");
      } else {
        var addr = false;
        // console.log("!Address");
      }
      if (this.account_number.length === 10) {
        var acct = true;
        // console.log("Number");
      } else {
        var acct = false;
        // console.log("!Number");
      }
      if (this.branch.length > 3) {
        // console.log("Branch");
        var br = true;
      } else {
        // console.log("!Branch");
        var br = false;
      }
      if (this.total > 100) {
        // console.log("Tott");
        var tot = true;
      } else {
        // console.log("!Tott");
        var tot = false;
      }

      if (addr && acct && br && tot) {
        this.completed = true;
        // console.log("completed");
      } else {
        this.completed = false;
        // console.log("ish");
      }
    },
    Submit() {
      if (this.completed) {
        var productArr = [];

        // console.log(this.$store.getters.cartProducts);

        this.$store.getters.cartProducts.forEach((item) => {
          var obj = {};

          obj.quantity = parseInt(item.quantity);
          obj.name = item.product.name;
          obj.price = item.product.price;
          obj.delivery_fee = item.product.delivery_cost;
          productArr.push(obj);

          // console.log(item);
        });
        // console.log(productArr);

        this.lodd = true;
        let data = {
          email: this.udet.email,
          home_address: this.address,
          bank_account: this.account_number,
          bank_branch: this.branch,
          equity_amount: this.equity ? parseFloat(this.equity) : 0,
          total_price: parseFloat(this.total),
          loan_amount: this.loan,

          product: productArr,
          invite_token: localStorage.getItem("identifier"),
        };

        let payload = {
          path: `/offline/${data.invite_token}`,
          data,
        };
        // console.log(payload);

        this.$store
          .dispatch("putRequest", payload)
          .then((resp) => {
            // console.log(resp);
            if (resp) {
              if (resp.status === 200) {
                this.$toast.success(
                  "Offline Transaction",
                  "Details updated. Please check back with admin",
                  this.$toastPosition
                );
                localStorage.removeItem("identifier");
                setTimeout(() => {
                  this.$router.push("/");
                }, 1000);
              } else {
                this.$toast.error(
                  "Offline Transaction",
                  "Something went wrong, Please use link again",
                  this.$toastPosition
                );
                localStorage.removeItem("identifier");
                setTimeout(() => {
                  this.$router.push("/");
                }, 1000);
              }
            } else {
              this.$toast.error(
                "Offline Transaction",
                "Link has expired",
                this.$toastPosition
              );
              localStorage.removeItem("identifier");
              setTimeout(() => {
                this.$router.push("/");
              }, 1000);
            }
          })
          .catch((err) => {
            // console.log(err);
            this.$toast.error(
              "Offline Transaction",
              "Your link might have expired. Contact Admin",
              this.$toastPosition
            );
            localStorage.removeItem("identifier");
            setTimeout(() => {
              this.$router.push("/");
            }, 1000);
          });
      } else {
        this.$toast.error(
          "Offline Transaction",
          "Please Fill all details",
          this.$toastPosition
        );
      }

      // this.$toast.info("Offline Transaction", "Posting", this.$toastPosition);
    },
  },
};
</script>

<style scoped>
.offline {
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #fff;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999999999999999999;
}
.imp {
  color: red;
}
.mt-60 {
  margin-top: 24px;
}
input#chh {
  margin-right: 5px;
}
input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}
.forc {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 52px;
  margin-block: 32px;
}
.sbtn:disabled,
.sbtn:disabled:hover {
  background-color: #e9ecef;
  color: #fff;
  cursor: not-allowed;
}
.sbtn {
  border: none;
  background-color: #255e13;
  transition: all 0.3s ease-in-out;
  padding: 8px 24px;
  border-radius: 12px;
  color: #fff;
  margin: auto;
  display: inline-block;
  font-weight: 600;
}
.sbtn:hover {
  background-color: #fdd826;
  color: #525f7f;
}
.concent input,
.concent label {
  cursor: pointer;
}

.concent {
  margin-top: 24px;
}

p.top {
  margin-top: 56px;
  margin-bottom: 24px;
  text-align: center;
}
form {
  width: 80%;
  margin: auto;
}
form .col-md-6 {
  margin-block: 15px;
}
.citm {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: 1px solid #255e13;
  border-top: none;
  border-bottom: none;
  /* border-radius: .25rem; */
  padding: 0 0.75rem;
}
.citm * {
  margin: 0;
}
</style>